import { Injectable } from '@angular/core';
import { WindowService } from './window.service';

@Injectable({ providedIn: 'root' })
export class CookiesService {

  private selectedCookies: { [key: string]: boolean } = {};

  constructor(
    private windowService: WindowService
  ) {
    this.retrieveSelectedCookies();
  }

  getSelectedCookieValue(cookieKey: string): boolean {
    return this.selectedCookies[cookieKey] === true;
  }

  hasAnalysisCookies(): boolean {
    return this.getSelectedCookieValue('ANALYSIS');
  }

  hasFunctionalityCookies(): boolean {
    return this.getSelectedCookieValue('FUNCTIONALITY');
  }

  retrieveSelectedCookies(): void {
    if (this.windowService.isBrowser) {
      const storedToggles = localStorage.getItem('selectedCookiesSM');
      this.selectedCookies = storedToggles ? JSON.parse(storedToggles) : {};
    }
  }
}
